
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
    reactive,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import CustomerCreditFilter from '@/components/dropdown/CustomerCreditFilter.vue';

  export default defineComponent({
    name: 'customer-credit-listing',
    components: {
      Datatable,
      CustomerCreditFilter,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();
      const loading = ref(false);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('financeCustomerCreditListing'), [
          translate('finance'),
        ]);
        loading.value = true;

        try {
          await Promise.all([
            store.dispatch(Actions.GET_FINANCE_CUSTOMER_CREDIT),
            store.dispatch(Actions.GET_ALL_CUSTOMERS),
          ]);
        } catch (error) {
          console.error(error);
        }

        loading.value = false;
        await store.dispatch(Actions.GET_ALL_FINANCE_CUSTOMER_CREDIT);
      });

      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_FINANCE_CUSTOMER_CREDIT_STORE);
        store.dispatch(Actions.RESET_FINANCE_INVOICE_STORE);
      });
      const tableHeader = ref([
        {
          name: translate('date'),
          key: 'date',
          sortable: true,
        },
        {
          name: translate('customer Name'),
          key: 'customerName',
          sortable: true,
        },
        {
          name: translate('service Type'),
          key: 'serviceType',
          sortable: true,
        },

        {
          name: translate('phone Number'),
          key: 'customerPhoneNumber',
          sortable: true,
        },
        {
          name: translate('amount'),
          key: 'amount',
          sortable: true,
        },
        {
          name: translate('discount'),
          key: 'discount',
          sortable: true,
        },
        {
          name: translate('Price Before Discount'),
          key: 'priceBeforeDiscount',
          sortable: true,
        },
        {
          name: translate('status'),
          key: 'status',
          sortable: true,
        },
        {
          name: translate('invoice Id'),
          key: 'slipNo',
          sortable: false,
        },
      ]);
      const excelHeader = reactive({
        Customer: 'customerName',
        Date: 'date',
        'Service Type': 'serviceType',
        'Phone Number': 'customerPhoneNumber',
        Amount: 'amount',
        Discount: 'discount',
        'Price Before Discount': 'priceBeforeDiscount',
        status: 'status',
      });

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const navigateToInvoice = (id) => {
        router.push({
          name: 'finance-invoice-listing',
          query: { invoiceId: id },
        });
      };

      const searchCustomerCredit = async (query) => {
        loading.value = true;
        await store.dispatch(Actions.RESET_ALL_FINANCE_CUSTOMER_CREDIT);
        await store.dispatch(Actions.UPDATE_CUSTOMER_CREDIT_FILTER, query);
        await store.dispatch(
          Actions.UPDATE_FINANCE_CUSTOMER_CREDIT_CURRENT_PAGE,
          1
        );
        await store.dispatch(Actions.GET_FINANCE_CUSTOMER_CREDIT);
        loading.value = false;
        await store.dispatch(
          Actions.GET_ALL_FINANCE_CUSTOMER_CREDIT,
          query.value
        );
      };
      const onReset = async (query) => {
        loading.value = true;
        await store.dispatch(Actions.RESET_ALL_FINANCE_CUSTOMER_CREDIT);
        await store.dispatch(Actions.UPDATE_CUSTOMER_CREDIT_FILTER, query);
        await store.dispatch(
          Actions.UPDATE_FINANCE_CUSTOMER_CREDIT_CURRENT_PAGE,
          1
        );
        await store.dispatch(Actions.GET_FINANCE_CUSTOMER_CREDIT);
        loading.value = false;
        await store.dispatch(Actions.GET_ALL_FINANCE_CUSTOMER_CREDIT, {});
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(
          Actions.UPDATE_FINANCE_CUSTOMER_CREDIT_CURRENT_PAGE,
          val
        );
        await store.dispatch(Actions.GET_FINANCE_CUSTOMER_CREDIT);
        loading.value = false;
      };

      const excelData = computed(() => {
        return (
          store.getters.allCustomerCreditsList?.data?.map((customerCredit) => {
            const status = customerCredit.status ? 'paid' : 'not paid';
            return {
              ...customerCredit,
              status: status,
            };
          }) || []
        );
      });

      return {
        tableData: computed(() => store.getters.customerCreditsList),
        tableHeader,
        excelData,
        excelHeader,
        translate,
        totalItems: computed(() => store.getters.customerCreditsListCount),
        pageChanged,
        goTo,
        loading,
        can,
        searchCustomerCredit,
        onReset,
        navigateToInvoice,
      };
    },
  });
